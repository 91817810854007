* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  width: auto;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg-navbar {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3D3D3D', endColorstr='#000000', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );
}

.gradient__bg-subpage {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}

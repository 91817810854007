@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);

.et__cta-wrapper {
  height: 10rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(67, 63, 63, 0.6);
  border-radius: 10px;
}

.et__cta-content {
  height: 8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.et__cta-header {
  padding: 1rem 0.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  color: #fff;
  z-index: 100;
}

.et__cta-header h2 {
  font-family: var(--font-family);
  z-index: 100;
  padding: 0.4rem 0;
}

.et__cta-header h3 {
  font-family: var(--font-family);
  z-index: 100;
}

.et__cta-button {
  background-color: red;
  color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
  justify-content: center;
  font-family: var(--font-family);
  cursor: pointer;
  z-index: 99;
  font-size: large;
  margin-bottom: 1rem;
}

.et__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 6rem;
  height: 15vh;
}

.et__navbar-links {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.et__navbar-links a:hover {
  border-bottom: 1px solid #fff;
}

.et__navbar-links_logo {
  margin-right: 2rem;
  margin-left: 1rem;
}

.et__navbar-links_logo img {
  width: 14rem;
  height: 8rem;
}

.et__navbar-links_container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.et__navbar-links_container p,
.et__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  cursor: pointer;
  margin: 0 1rem;
}

.et__navbar-menu_container a:hover {
  border-bottom: 1px solid #fff;
}

.et__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  z-index: 1000;
}

.et__navbar-menu svg {
  cursor: pointer;
}

.et__navbar-menu_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0, 0, 5, rgba(0, 0, 0, 0.2);
}

.et__navbar-menu_container p {
  margin: 0 1rem;
}

.et__navbar-menu_container-links p {
  margin: 0.8rem;
}

.gradient__bg-menu {
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 20% 80%,
    rgba(88, 88, 88, 1) 0%,
    rgba(188, 188, 188, 1) 99%
  );
}

@media screen and (max-width: 1050px) {
  .et__navbar-links_container {
    display: none;
  }

  .et__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .et__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .et__navbar {
    padding: 2rem;
  }

  .et__navbar-menu_container {
    top: 20px;
  }
}

@media screen and (max-width: 410px) {
  .et__navbar-links_logo img {
    width: 10rem;
  }
}

.et__form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: var(--font-family);
}

.et__form-container {
  padding: 2rem;
  box-shadow: 0, 0, 5, rgba(0, 0, 0, 0.2);
}

.et__form {
  padding: 1rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  border: 2px solid red;
  border-radius: 4px;
  justify-content: center;
  background-color: #fff;
}

.et__form h3 {
  margin: 4px;
}

.et__form-title {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.et__form-title h2 {
  text-align: center;
}

.et__form-title p {
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
}

.et__form label {
  display: flex;
  flex-direction: column;
  margin: 4px;
  flex: 1 1;
}

.et__form input {
  width: 100%;
  height: 2.4rem;
  border-radius: 2px;
  padding: 2px;
  background-color: rgb(235, 235, 235);
}

.double {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.et__form-info input {
  height: 8rem;
  margin-top: 6px;
}

textarea {
  margin-top: 4px;
  background-color: rgb(235, 235, 235);
}

.et__form-radio-option {
  display: flex;
  align-items: center;
  margin: 4px;
  height: 2rem;
}

.et__form-radio-option input {
  width: 1rem;
  margin: 2px;
  justify-content: center;
}

.submitButton {
  justify-content: center;
  height: 3rem;
  width: 6rem;
  color: #fff;
  background-color: red;
  border: none;
  font-size: large;
}
.et__form-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2px;
}

.error {
  border: 2px solid #eb516d;
}

button:active {
  background-color: gray;
}

@media screen and (max-width: 750px) {
  .double {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .et__form {
    width: 30rem;
  }
}

@media screen and (max-width: 560px) {
  .et__form {
    width: 24rem;
  }
  .et__form-container {
    padding: 1rem;
  }
}

@media screen and (max-width: 460px) {
  .et__form {
    font-size: medium;
  }

  .et__form {
    width: 22rem;
  }
}

@media screen and (max-width: 365px) {
  .et__form {
    width: 18rem;
  }
}

.et__header-contact {
  display: flex;
  background-color: var(--color-dark-red);
  width: 100%;
  justify-content: center;
  color: white;
  font-family: var(--font-family);
  font-size: large;
  border-top: 1px solid;
  border-color: gold;
}

.et__header-contact-cta {
  padding: 8px;
}

.et__header-contact-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.et__header-contact-item p {
  margin-left: 4px;
}

.et__header-contact-items {
  display: flex;
}

@media screen and (max-width: 750px) {
  .et__header-contact {
    font-size: medium;
  }
}

@media screen and (max-width: 500px) {
  .et__header-contact {
    font-size: small;
  }
}

@media screen and (max-width: 365px) {
  .et__header-contact-items {
    flex-direction: column;
  }
}

.et__footer-wrapper {
  display: flex;
  height: 0rem;
  width: 100%;
}

.et__header-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid;
  border-color: gold; */
  box-shadow: 5px 10px 18px #888888;
}

.et__header-content {
  width: 100%;
  height: 75vh;
  background-image: url(/static/media/image2.3510fa68.jpeg);
  background-position: 50% 30%;
  background-size: cover;
  display: flex;
}

.shadow {
  background-size: cover;
}

.shadow::after {
  content: " ";
  height: 75vh;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  opacity: 0.6;
  z-index: 1;
}

.et__header-cta-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.et__banner-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .et__header-content {
    height: 35rem;
  }
  .shadow::after {
    height: 35rem;
  }
}

@media screen and (max-width: 750px) {
  .et__header-content {
    height: 25rem;
  }

  .shadow::after {
    height: 25rem;
  }

  .et__header-content h2 {
    font-size: large;
  }

  .et__header-content h3 {
    font-size: medium;
  }

  .et__header-cta-zone a {
    font-size: medium;
  }

  .et__header-contact-cta {
    display: none;
  }

  .et__header-contact {
    font-size: medium;
  }
}

@media screen and (max-width: 500px) {
  .et__header-content {
    height: 20rem;
  }

  .shadow::after {
    height: 20rem;
  }

  .et__header-content h2 {
    font-size: large;
  }

  .et__header-content h3 {
    font-size: medium;
  }

  .et__header-contact {
    font-size: small;
  }

  .et__header-cta-zone a {
    font-size: medium;
  }

  .et__cta-wrapper {
    height: 8rem;
    background-color: rgba(67, 63, 63, 0.3);
  }

  .et__cta-header {
    padding: 0;
  }

  .et__cta-content {
    padding: 5px;
    border-radius: 3px;
  }

  .et__cta-button {
    margin: 1rem;
  }
}

@media screen and (max-width: 365px) {
  .et__header-contact-items {
    flex-direction: column;
  }
}

.et__features-wrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.et__features-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.et__features-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.et__features-tiles {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.et__features-tile {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 2rem;
  min-height: 16vh;
  text-align: center;
  font-family: var(--font-family);
  font-size: large;
  color: #fff;
}

.et__features-tile h3 {
  margin: 4px;
}

.t1 {
  background-color: #c21e31;
}

.t2 {
  background-color: #b01c2d;
}

.t3 {
  background-color: #9f1928;
}

.t4 {
  background-color: #8d1624;
}

.icons {
  justify-content: center;
  width: 100%;
  height: 2rem;
}
@media screen and (max-width: 1050px) {
  .et__features-tile {
    font-size: medium;
  }
}

@media screen and (max-width: 939px) {
  .et__features-tile {
    flex: 1 1 50vw;
    min-height: 220px;
  }
}

@media screen and (max-width: 560px) {
}

@media screen and (max-width: 460px) {
  .et__features-tile {
    font-size: small;
    padding: 1rem;
    min-height: 200px;
  }
}

@media screen and (max-width: 365px) {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  width: auto;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg-navbar {
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3D3D3D', endColorstr='#000000', GradientType=1 );

  /* ie 10+ */

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(61, 61, 61, 1) 100%
  );
}

.gradient__bg-subpage {
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 20% 80%,
    rgba(198, 198, 198, 1) 0%,
    rgba(255, 255, 255, 1) 99%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  --color-dark-red: #e02a32;
}

